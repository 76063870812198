<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>CASE Associations</h3>

	<p>Each <b>association</b> in a framework’s CASE JSON relates a CASE item to another CASE item, or to some other entity (e.g. an “exemplar” of the item). Each association object specifies metadata including:<ul>
			<li><b>Identifier</b> (required): <span v-html="link('identifiers', 'a globally unique identifier')"></span> meant to serve as an unambiguous reference to the association for any digital system that consumes the association’s CASE JSON</li>
			<li><b>URI</b> (required): a network-resolvable URI (usually a URL, meaning a resolvable web location) representing the association</li>
			<li><b>Origin</b> (required): the origin “node” for the association, expressed as an identifier (GUID), a URI, and a title (where the value in the title field varies from system to system)</li>
			<li><b>Destination</b> (required): the destination “node” for the association, expressed as an identifier (GUID), a URI, and a title (where the value in the title field varies from system to system) <ul>
				<li>For item-to-item associations, the origin item and destination item are specified by the two items’ identifiers (GUIDs).</li>
			</ul></li>
			<li><b>Association Type</b> (required): The type of association this entity represents. <ul>
				<li>CASE specifies a limited vocabulary of association types. The most-commonly used are: <ul>
					<li>Related To (<code>isRelatedTo</code>): a generic indication that the origin and destination are related in some way</li>
					<li>Exact Match (<code>exactMatchOf</code>): an assertion that the origin and destination are equivalent entities</li>
					<li>Is Child Of (<code>isChildOf</code>): these associations define the hierarchical “tree structure” of a framework</li>
				</ul></li>
				<li>Other possible values are “isPeerOf”, “isPartOf”, “precedes”, “replacedBy”, “exemplar”, “hasSkillLevel”, and “isTranslationOf” (with the latter added in CASE 1.1).</li>
			</ul>
			<li><b>Sequence Number</b>: for a set of isChildOf associations that define the “children” of a “parent” in the framework hierarchy, the sequence number value (which should be an integer) for each association determines the order in which the children should appear.</li>
			<li><b>Last Changed Date</b> (required): The date and time when the association was last changed </li>
		</ul></li>
	</ol>
	<p>Note that this is not an exhaustive lists of all fields that may be included in association records. For full details see the official <a href="https://www.imsglobal.org/sites/default/files/CASE/casev1p0/information_model/caseservicev1p0_infomodelv1p0.html" target="_blank">CASE 1.0 Information Model</a>.</p>
	<p>Here’s what the CASE JSON looks like for a typical <code>isRelatedTo</code> association:</p>
<pre style="font-size:10px; line-height:13px;">
{
  "identifier": "d6c96f8c-0b6b-47ae-be26-845de845fd07",
  "uri": "https://state.standards.org/uri/d6c96f8c-0b6b-47ae-be26-845de845fd07",
  "associationType": "isRelatedTo",
  "originNodeURI": {
    "title": "ELAGSEKRL6 With prompting and support, name the author...",
    "identifier": "2a0b15f4-46fa-11e7-8240-7d894e3279c9",
    "uri": "https://uatcase.gadoe.org/uri/2a0b15f4-46fa-11e7-8240-7d894e3279c9"
  },
  "destinationNodeURI": {
    "title": "RL.K.6 With prompting and support, name the author and ...",
    "identifier": "6b38de2a-d7cc-11e8-824f-0242ac160002",
    "uri": "https://casenetwork.imsglobal.org/uri/6b38de2a-d7cc-11e8-824f-0242ac160002"
  },
  "lastChangeDateTime": "2022-10-16T21:18:15+00:00"
}
</pre>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	